<template>
  <div class="container mx-auto md:mt-24 mt-12 pb-12">
    <div class="md:w-9/12 w-full text-center flex flex-col gap-y-4 mx-auto">
      <h3 class="text-header-xl text-center font-bold">
        {{ introSection.title }}
      </h3>
      <div class="pt-4 text-sm md:text-base flex flex-col gap-4">
        <p class="text-foreground/70">
          {{ introSection.description }}
        </p>
        <p>
          {{ introSection.paragraph }}
        </p>
      </div>
    </div>

    <div
      class="w-full md:rounded-full rounded-3xl flex items-center justify-center bg-gradient-to-tr to-[#FEF5FB] from-[#F4FBFE] p-12 mt-12"
    >
      <div
        class="w-full h-full bg-white md:rounded-full rounded-3xl shadow-sm box-shadow flex flex-none flex-wrap"
      >
        <template v-for="(item, i) in introSection.items">
          <div
            class="w-full sm:w-1/2 lg:flex-1 items-center justify-center flex flex-col gap-y-2 h-24"
          >
            <h4
              class="text-3xl lg:text-4xl primary-gradient bg-clip-text text-transparent"
            >
              {{ item.focus }}
            </h4>
            <span class="text-sm lg:text-base">{{ item.description }}</span>
          </div>

          <div
            v-if="i < introSection.items.length - 1"
            class="hidden lg:block h-16 w-1 bg-foreground/20 rounded my-auto"
          ></div>
        </template>
      </div>
    </div>
    <div class="flex justify-center items-center">
      <Button
        aria-label="Book free consultation"
        class="primary-gradient capitalize mx-auto mt-12 flex gap-2"
        @click="openModal"
        >Book free consultation
        <Icon icon="mdi-arrow-right"></Icon>
      </Button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Icon } from "@iconify/vue/dist/iconify.js";
import { introSection } from "~/lib/constants";
import { useModal } from "vue-final-modal";
import ContactModal from "~/components/global/ContactModal.vue";

const openModal = async () => {
  const modal = useModal({
    component: ContactModal,
  });

  await modal.open();
};
</script>

<style scoped></style>
